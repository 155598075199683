import request from '@/utils/request'

export function getPriceChanged(params) {
  return request({
    method: 'get',
    url: 'goods-report/price-changed',
    params
  })
}

export function getPriceChangedDetail(params) {
  return request({
    method: 'get',
    url: 'goods-report/price-changed-detail',
    params
  })
}

export function getGift(params) {
  return request({
    method: 'get',
    url: 'goods-report/gift',
    params
  })
}

export function getGiftDetail(params) {
  return request({
    method: 'get',
    url: 'goods-report/gift-detail',
    params
  })
}
