var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "改价商品",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-container"
    }, [_c('div', {
      staticClass: "goods"
    }, [_c('div', {
      staticClass: "goods-image"
    }, [_c('van-image', {
      attrs: {
        "src": goods.image
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(goods.name))]), _c('div', {
      staticClass: "goods-size"
    }, [_vm._v("规格：1 * " + _vm._s(goods.size))])])]), _c('van-cell', {
      attrs: {
        "title": "查看详情",
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(goods.goods_id);
        }
      }
    })], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }